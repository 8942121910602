<template>
  <a-form-model layout="vertical" @submit.prevent="login()">
    <a-form-model-item label="Correo electrónico">
      <a-input placeholder="Ingrese su correo electrónico" autocomplete="username" v-model="email" ref='email'/>
    </a-form-model-item>
    <a-form-model-item label="Contraseña">
      <a-input-password placeholder="Ingrese su contraseña" autocomplete="current-password" v-model="password" />
      <div class="mt-2 text-left text-sm flex flex-col justify-center items-center sm:flex-row sm:justify-between">
        <span class="my-1 flex justifycenter items-center text-xs"><input type="checkbox" id="remember" class="mr-2 rounded"> Recordarme</span>
        <router-link to="/recovery" class="my-1 text-blue text-xs"><b>¿Olvidó su contraseña?</b></router-link>
      </div>
    </a-form-model-item>
    <a-form-model-item v-if="feedback">
        <p class="text-sm text-red-500">{{feedback}}</p>
    </a-form-model-item>
    <a-form-model-item >
      <a-button type="primary" html-type="submit" shape="round" :block="true" :loading="loading" :disabled="!email || !password">
        <span class="mx-2">INICIAR SESIÓN</span>
      </a-button>
    </a-form-model-item>
    <ModalInactivateResponse :visible="showModal" :group_flag="groupFlag" @toggle-modal="toggleModal"/>
  </a-form-model>
    
</template>
<script>
import ModalInactivateResponse from "./ModalInactivateResponse.vue";
export default {
  components: {
    ModalInactivateResponse
  },
    data(){
        return {
            email: '',
            password: '',
            feedback: '',
            loading: false,
            showModal: false,
            groupFlag: false
        }
    },
    mounted(){
      this.$refs.email.$el.focus()
    },
    methods: {
      async login(){
        this.loading = true;
        this.$store.dispatch('session/login', {email: this.email?.toLowerCase().trim(), password:this.password}).then((res)=>{
          if (res === undefined) {
            throw 'Error en inicio de sesión'
          }
          this.loading = false;
          if(!this.$store.state.session.user.is_active){
            if (this.$store.state.session.user.id_company?.id === 1) {
              this.groupFlag = !this.$store.state.session.user.group_state;
              this.showModal = true;
            } else {
            this.$router.push('/verify');
            }
          } else {
            // Pero el rol se encuentra inactivo igual no se deja iniciar sesión.
            if (this.$store.state.session.user.id_company?.id === 1 && !this.$store.state.session.user?.group_state) {
              this.groupFlag = !this.$store.state.session.user.group_state;
              this.showModal = true;
              return;
            } 
            this.$router.push('/').catch(()=>{});
          }
        })
        .catch(error => {
          if (error?.error == 'Los datos no coinciden') {
            this.feedback = 'Usuario y/o contraseña no son válidos';
          } else if (error?.error) {
            this.feedback = error?.error;
          } else {
            this.feedback = 'Error con el servidor, intente más tarde.';
          }
          
          this.loading = false;
        })
      },
      checkForm(){
        let validation = false;
        if(!this.$validator.isEmail(this.email) || this.$validator.isEmpty(this.password)){
          this.feedback = 'Verifique la información';
        } else {
          this.feedback = '';
          validation = true;
        }
        return validation;
      },
      toggleModal(value) {
        this.showModal = value;
      }
    }
}
</script>

<style>

</style>